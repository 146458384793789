import { LOCAL_KEY } from '@/core/store';
import NextImagePro, { ImageProps as NextImageProps } from 'next/image';
import { FunctionComponent, MouseEventHandler, useEffect, useState } from 'react';
import { useHover } from 'react-use';
import CommonIcon from '../common-icon';
import { extractLastTwoWords, modifyImagePath } from './helper';

type CustomImageProps = Omit<NextImageProps, 'alt'> & {
  src: string;
  className?: string;
  alt?: string;
  enableSkin?: boolean;
  width: number | string;
  height: number | string;
  shouldHideOnError?: boolean;
  onClick?: MouseEventHandler<HTMLImageElement>;
};

const Image: FunctionComponent<CustomImageProps> = ({
  src,
  className,
  alt,
  width,
  height,
  enableSkin = false,
  shouldHideOnError = false,
  ...props
}) => {
  const [NextImage, setNextImage] = useState<FunctionComponent<NextImageProps> | null>(null);
  const [isVisible, setIsVisible] = useState(true);

  useEffect(() => {
    if (enableSkin) {
      import('next/image').then((mod) => {
        setNextImage(() => mod.default);
      });
    } else {
      setNextImage(NextImagePro);
    }
  }, [enableSkin]);

  const isClient = typeof window !== 'undefined';

  let modifiedSrc = src;
  if (enableSkin && isClient) {
    const skin = document.documentElement.getAttribute(LOCAL_KEY.DATA_SKIN) || '';
    console.log('skin', skin);
    modifiedSrc = modifyImagePath(src, enableSkin, skin);
  }
  const handleError = () => {
    if (shouldHideOnError) {
      setIsVisible(false); // 当 shouldHideOnError 为 true 时，隐藏图片
    }
  };
  const imageProps = {
    className,
    src: modifiedSrc,
    alt: alt || extractLastTwoWords(src),
    width,
    height,
    onError: handleError,
    ...props,
  };

  return <>{NextImage && isVisible && <NextImage {...imageProps} />}</>;
};

export const ImageHover = ({
  src,
  hoverSrc,
  hovered: _hovered,
  ...props
}: {
  src: string;
  hoverSrc: string;
  hovered?: boolean;
  className?: string;
  width?: any;
  height?: any;
  enableSkin?: boolean;
  onClick?: MouseEventHandler<HTMLImageElement>;
}) => {
  const element = (hovered: boolean) => <CommonIcon name={hovered || _hovered ? hoverSrc : src} {...props} />;
  const [hoverable] = useHover(element);

  return hoverable;
};

export default Image;
